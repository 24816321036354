import React  from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Intersect from "../components/useIntersect"
import "../components/layout.css"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
   <Intersect/>
  </Layout>
)

export default Contact